import React from "react";
import Image from "../Hacks/Image";

// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

//import Fade from "react-reveal/Fade";
// import Pulse from "react-reveal/Pulse";

import { Fade } from "react-awesome-reveal";
// import ReactPlayer from "react-player";

export const HomeHero = ({
  intro_subheader,
  intro_hello,
  intro_title,
  intro_intro,
  ctaLink,
  ctaText,
  cta2Link,
  cta2Text,
  intro_image,
  intro_image_mobile,
  testimonial_image,
  testimonial_from,
  testimonial,
  logo_title,
  logos,
}) => {
  const testimonialHTML = (
    <div className="card rounded small shadow bg-light mt-lg-5">
      <div className="card-body">
        <div className="mb-2">
          <strong>"</strong> <span className="font-italic">{testimonial}</span>
        </div>
        <div className="row mt-3">
          <div className="col-3 col-lg-4">
            <Image
              image={testimonial_image}
              alt={testimonial_from}
              className="img-fluid rounded-circle"
            />
          </div>
          <div className="col-9 col-lg-8 pl-0">
            <strong>{testimonial_from}</strong>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <header
        id="hero"
        className="hero hero_home hero-alt3 pb-0 pt-5 bg-primary"
      >
        <div className="position-relative container-fluid">
          <div className="row justify-content-center contained-xl py-md-4 pt-lg-5 position-relative">
            <div className="order-2 order-lg-1 col-11 col-md-10 col-lg-5 text-center text-lg-left text-secondary align-self-center py-4 pt-lg-0">
              <h1 className="h2 mb-4">
                <span className="d-block display-2 mb-0 ">{intro_hello}</span>{" "}
                <span className="h2 font-family-display mt-0">
                  {intro_title}
                </span>
              </h1>

              <div
                className="lead mb-4"
                dangerouslySetInnerHTML={{
                  __html: intro_intro,
                }}
              />
              <div>
                {ctaLink && (
                  <Link
                    to={ctaLink}
                    className="btn btn-secondary text-primary mt-1 mt-3 btn-sm main-cta ml-2 ml-lg-0 mr-2"
                  >
                    {ctaText}
                  </Link>
                )}
                {cta2Link && (
                  <Link
                    to={cta2Link}
                    className="btn btn-outline-secondary mt-1 mt-3 btn-sm ml-2 ml-lg-0 main-cta mr-2"
                  >
                    {cta2Text}
                  </Link>
                )}
              </div>
            </div>
            <div className="order-1 offset-lg-3 col-lg-2 mt-5 pt-lg-5 position-absolute z-index-100 text-black d-none d-lg-block">
              {testimonialHTML}
            </div>
            <div className="order-4 col-10 col-md-7 col-lg-2 py-lg-4 text-black d-lg-none mt-4 mb-5">
              {testimonialHTML}
            </div>
            <div className="order-1 order-lg-1 offset-lg-2 col-11 col-md-7 col-lg-3 align-self-center pt-4">
              {intro_image && intro_image_mobile && (
                <>
                  <Image
                    image={intro_image}
                    className="img-fluid rounded-lg shadow d-none d-lg-block"
                    alt=""
                  />
                  <Image
                    image={intro_image_mobile}
                    className="img-fluid rounded-lg shadow d-lg-none"
                    alt=""
                  />
                </>
              )}
            </div>

            {logos && (
              <div className="order-3 order-lg-2 col-lg-8 mt-5">
                <Fade delay={250}>
                  <section
                    id="partners"
                    className="text-md-right text-center container-fluid"
                  >
                    {logo_title && (
                      <h2 className="h5 text-center text-uppercase">
                        {logo_title}
                      </h2>
                    )}
                    <div className="row contained-xl justify-content-center align-items-center">
                      {logos.map(function (logo, i) {
                        return (
                          <div
                            className="col-4 col-md-2 mb-3 px-4"
                            key={"partner" + i}
                          >
                            <Image
                              image={logo}
                              backgroundColor="transparent"
                              placeholder="blurred"
                              className="img-fluid w-100"
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </Fade>
              </div>
            )}
          </div>
        </div>
      </header>
      <div id="afterheader"></div>
    </React.Fragment>
  );
};

export default HomeHero;
