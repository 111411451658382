import React from "react";
import Image from "../Hacks/Image";

export const LargeQuote = ({
  background,
  largequote_image,
  quote,
  quotee,
  quotee_title,
}) => {
  return (
    <section
      className={`large-quote bg-${
        background ? background : "light"
      } text-center`}
    >
      {largequote_image && (
        <div className="large-quote-image floaty" data-scroll-speed-y="-10">
          <Image image={largequote_image} className="w-100" alt="" />
        </div>
      )}
      <div className={`py-5 row justify-content-center`}>
        <div
          className={`col-11 ${
            largequote_image ? `py-5 col-lg-7` : `col-lg-6`
          }  align-middle d-flex align-items-center`}
        >
          <div
            className={`w-100 ${largequote_image ? `py-5 my-md-5` : `mt-4`}`}
          >
            <blockquote
              className={` ${largequote_image ? `h3` : `h3`} font-italic`}
            >
              {quote}
            </blockquote>
            {quotee && <div className="h5 text-secondary">{quotee}</div>}
            {quotee_title && (
              <div className="h6 text-secondary">{quotee_title}</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LargeQuote;
