import React from "react";
import Content from "../Hacks/Content";

export const Testimonials = ({ title, cards }) => {
  var colmd = "col-md-" + 36 / cards.length;
  var collg = "col-lg-" + 12 / cards.length;

  return (
    <section className={`testimonials container-fluid mt-5 pt-5`}>
      <div className={`row contained-xl justify-content-center`}>
        <div className={`col-md-10`}>
          {title && <h2 className="text-center mb-4">{title}</h2>}
          {cards && (
            <div className="row justify-content-center">
              {cards.map(function (card, i) {
                return (
                  <div
                    className={`${colmd} ${collg} mb-4`}
                    key={`card` + i}
                  >
                    <div className="card bg-white rounded-sm h-100 align-content-bottom">
                      <div className="card-body p-xl-5">
                        <div className="text-right">
                          <i className="fal fa-4x text-light mb-0 fa-quote-right"></i>
                        </div>
                        {card.title && (
                          <h3 className="h4 mb-3 text-secondary">
                            {card.title}
                          </h3>
                        )}
                        {card.content && <Content content={card.content} />}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default Testimonials;
