import React from "react";
import { Link } from "gatsby";

export const SimpleCTA = ({ title, backgroundColour, cta_1_link, cta_1_text }) => {
  return (
    <section className={`simplecta container-fluid bg-${backgroundColour}`}>
      <div className="py-5 row justify-content-center text-center">
        <div className="col-lg-6 col-md-10">
          <h2 className="font-family-display mb-4">{title}</h2>
          <div>
            {cta_1_link && !cta_1_link.includes("mailto:") ? (
              <Link className="btn btn-outline-secondary mt-3" to={cta_1_link}>
                {cta_1_text}
              </Link>
            ) : (
              <a className="btn  btn-outline-secondary" href={cta_1_link}>
                {cta_1_text}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleCTA;
