import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import ModuleLayout from "../components/ModuleLayout";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

const PageTemplate = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout
      backgroundColour={frontmatter.backgroundColour}
      darkMenu={frontmatter.darkMenu}
      accentColour={frontmatter.accentColour}
      hideNav={frontmatter.hideNav}
      hideFooter={frontmatter.hideFooter}
      hideSEO={frontmatter.hideSEO}
      hideIntercom={frontmatter.hideIntercom}
    >
      <Seo
        title={
          frontmatter.path === "/"
            ? meta.siteTitle + meta.sitePageTitleSep + frontmatter.title
            : frontmatter.title + meta.sitePageTitleSep + meta.siteTitle
        }
        slug={meta.siteUrl + frontmatter.path}
        description={
          frontmatter.metaDescription
            ? frontmatter.metaDescription
            : frontmatter.excerpt
            ? frontmatter.excerpt
            : meta.siteDescription
        }
        image={
          meta.siteUrl +
          (frontmatter.metaImage
            ? frontmatter.metaImage.publicURL
            : frontmatter.image && frontmatter.image.publicURL
            ? frontmatter.image.publicURL
            : meta.siteImage)
        }
        twitterTitle={
          frontmatter.path === "/"
            ? meta.siteTitle + meta.sitePageTitleSep + frontmatter.title
            : frontmatter.title + meta.sitePageTitleSep + meta.siteTitle
        }
        facebookTitle={
          frontmatter.path === "/"
            ? meta.siteTitle + meta.sitePageTitleSep + frontmatter.title
            : frontmatter.title + meta.sitePageTitleSep + meta.siteTitle
        }
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
        hideSEO={frontmatter.hideSEO}
      />

      <ModuleLayout
        slug={frontmatter.path}
        page={data.markdownRemark.fields}
        blocks={frontmatter.content_blocks}
      />
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        path

        hideNav
        hideFooter
        hideSEO
        hideIntercom

        title
        heading
        subheading

        metaDescription
        metaImage {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        darkMenu
        backgroundColour
        accentColour

        darkMenu
        backgroundColour

        content_blocks {
          type

          subtitle

          thumbnail
          full_width
          video
          caption
          controls
          autoplay
          autoplay

          hosts {
            title
            name
            text
            linkedin
            featuredimage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }

          numbers

          timeline {
            title
            icon
            contentContent
            contentTitle
            contentSub
            left_or_right
            accent
          }
          images {
            alt
            width
            marginTop
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          headerType
          massiveHero
          title
          subheading
          intro

          header0
          header1
          header2

          color

          cta_1_link
          cta_1_text
          cta_1_button
          cta_2_link
          cta_2_text
          cta_3_link
          cta_3_text

          # portalId
          # formId

          # include_side_content
          side_title
          side_content
          url

          # leads_title
          # volume_title
          # save_title
          # button_text
          # disclaimer

          body
          content

          background
          align
          full_width

          image {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          image_mobile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          image_leftright

          faqs {
            question
            answer
          }

          statsTitle
          statsIntro
          stats {
            stat
            subtitle_1
            subtitle_2
          }

          testimonialFrom
          testimonialContent
          testimonialImage {
            childImageSharp {
              gatsbyImageData
            }
          }

          benefits {
            title
            text
          }
          benefit_intro
          benefit_title

          columns {
            title
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            content
          }
          # color

          cards {
            title
            intro
            subtitle
            content
            color
            link

            card_monthly_price
            card_yearly_price
            card_multiplier
            card_features
          }

          videos {
            title
            intro
            video
            background
            playing
            rightside
            cta_1_link
            cta_1_text
            button
            captions {
              name
              content
              top
              left
              arrow
              start
              end
            }
          }

          logo_title
          fullHeight
          logos {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;
