import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PartnerCard from "./PartnerCard";

const PartnersQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fields: { slug: { regex: "//partners//", ne: "/pages/partners/" } }
          frontmatter: { unpublished: { ne: true }, hideSEO: { ne: true } }
        }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            timeToRead
            frontmatter {
              title
              path
              url
              excerpt
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.edges;
};
const Partners = ({ preview }) => {
  var posts = [];
  if (!preview) {
    posts = PartnersQuery();
  } else {
    posts = [
      {
        node: {
          timeToRead: 1,
          frontmatter: {
            title: "Your partners will show here...",
            path: "/competitions/",
            excerpt:
              "This is a placeholder, the partner show up ont the live site",
            image: "",
          },
          fields: {
            slug: "/partners/",
          },
        },
      },
    ];
    posts[1] = posts[0];
  }

  return (
    <section className="pt-5 bg-light">
      <section className="postlists container-fluid">
        <div className="row contained-xl justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="row pb-5 contained-xl">
              {posts &&
                posts.map(function ({ node: post }, index) {
                  return (
                    <React.Fragment key={"block_1_" + index}>
                      <div className={`col-lg-3 col-md-6 mb-4`}>
                        <PartnerCard
                          featured_media={post.frontmatter.image}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          excerpt={post.frontmatter.excerpt}
                          tag={post.frontmatter.tags}
                          slug={post.fields.slug}
                          readtime={post.timeToRead}
                          url={post.frontmatter.path}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Partners;
