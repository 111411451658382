import React from "react";
import Image from "../Hacks/Image";
import Content from "../Hacks/Content";

export const Columns = ({ columns, title, full_width }) => {
  var colmd = "col-md-" + Math.floor(6 / columns.length);
  var collg = "col-lg-" + Math.floor(12 / columns.length);
  if (collg < 2) {
    collg = 2;
  }
  return (
    <section className={`columns text text-center container-fluid `}>
      <div className={`row contained-xl justify-content-center `}>
        <div className={`${full_width === "true" ? "col-md-12" : "col-md-10"}`}>
          {title && <h2 className="mb-5">{title}</h2>}
          <div className="row justify-content-center">
            {columns &&
              columns.map((column, index) => (
                <div
                  className={`columns_column col-6 ${colmd} ${collg} mb-3`}
                  key={`column-${index}`}
                >
                  {column.image && (
                    <Image
                      image={column.image}
                      className="img-fluid mb-4"
                      alt=""
                    />
                  )}
                  {column.title && (
                    <h3 className="h5 text-primary">{column.title}</h3>
                  )}
                  {column.content && <Content content={column.content} />}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Columns;
