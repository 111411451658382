import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CompetitionCard from "./CompetitionCard";
import CompetitionCardMicro from "./CompetitionCardMicro";

const CompetitionsQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { slug: { regex: "//competitions//" } } }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 9
      ) {
        edges {
          node {
            timeToRead
            frontmatter {
              title
              path
              url
              date(formatString: "MMMM DD, YYYY")
              excerpt
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges;
};
const Competitions = ({ mini, title, background, preview }) => {
  var posts = [];
  if (!preview) {
    posts = CompetitionsQuery();
  } else {
    posts = [
      {
        node: {
          timeToRead: 1,
          frontmatter: {
            title: "Your Latest Comps will show here...",
            path: "/competitions/",
            excerpt:
              "This is a placeholder, the comps show up based on latest content",
            image: "",
          },
          fields: {
            slug: "/competitions/",
          },
        },
      },
    ];
    posts[1] = posts[0];
  }

  return (
    <section className={`bg-${background ? background : "white"}`}>
      <header className="container-fluid">
        <div className="row contained-xl justify-content-center">
          <div className="col-lg-10 mt-5 mb-4">
            <h2 className="font-family-display">{title}</h2>
          </div>
        </div>
      </header>
      <section className="postlists container-fluid">
        <div className="row contained-xl justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="row pb-5 contained-xl">
              {posts &&
                posts.map(function ({ node: post }, index) {
                  return (
                    <React.Fragment key={"block_1_" + index}>
                      {(index === 0 || (index === 1 && posts.length === 2)) && (
                        <div
                          className={`col-lg-${
                            posts.length === 1 ? "12" : "6"
                          } mb-4`}
                        >
                          <CompetitionCard
                            featured_media={post.frontmatter.image}
                            title={post.frontmatter.title}
                            date={post.frontmatter.date}
                            excerpt={post.frontmatter.excerpt}
                            tag={post.frontmatter.tags}
                            slug={post.fields.slug}
                            readtime={post.timeToRead}
                            url={post.frontmatter.url}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              {posts.length > 2 && (
                <div className="col-md-6 mb-lg-4">
                  <div className="row h-100">
                    {posts &&
                      posts.map(function ({ node: post }, index) {
                        return (
                          <React.Fragment key={"block_1_" + index}>
                            {index > 0 && index < 3 && (
                              <div className="col-md-6 col-lg-12 mb-4">
                                <CompetitionCardMicro
                                  featured_media={post.frontmatter.image}
                                  title={post.frontmatter.title}
                                  date={post.frontmatter.date}
                                  excerpt={post.frontmatter.excerpt}
                                  tag={post.frontmatter.tags}
                                  slug={post.fields.slug}
                                  readtime={post.timeToRead}
                                  url={post.frontmatter.url}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              )}
              {posts.length > 3 && (
                <div className="col-md-12 mb-lg-4">
                  <div className="row h-100">
                    {posts &&
                      posts.map(function ({ node: post }, index) {
                        return (
                          <React.Fragment key={"block_1_" + index}>
                            {index > 2 && (
                              <div className="col-md-6 mb-4">
                                <CompetitionCardMicro
                                  featured_media={post.frontmatter.image}
                                  title={post.frontmatter.title}
                                  date={post.frontmatter.date}
                                  excerpt={post.frontmatter.excerpt}
                                  tag={post.frontmatter.tags}
                                  slug={post.fields.slug}
                                  readtime={post.timeToRead}
                                  url={post.frontmatter.url}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Competitions;
