import React, { useState } from "react";
import ContentBlock from "../Hacks/Content";

export const FAQBlock = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`faq-card ${open && "show"}`}>
      <div
        className={`position-relative faq-card-header mb-2`}
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        role="button"
        tabIndex="0"
      >
        <h3 className="h5 mb-0">{question}</h3>
      </div>
      <div className={`faq-card-body mt-3`}>
        <ContentBlock content={answer} className="mb-4 ml-4 pl-2" />
      </div>
    </div>
  );
};

export default FAQBlock;
