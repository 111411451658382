import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

export default class CompetitionCardMicro extends React.Component {
  render() {
    const {
      featured_media,
      title,
      excerpt,
      url,
    } = this.props;

    return (
      <div className="rounded bg-primary overflow-hidden card h-100">
        <Fade>
          <>
            <div className="row h-100">
              <div className="col-5 h-100">
                {featured_media && (
                  <Image
                    image={featured_media}
                    className="w-100 h-100 bg-light card-journal__image"
                    alt={title}
                  />
                )}
                {!featured_media && (
                  <div className="card-journal__image bg-primary" />
                )}
              </div>

              <div className="col-7 h-100 small">
                <div className="pr-4 py-4 h-100">
                  <h2 className="h5 font-family-display card-title">
                    <span>{title}</span>
                  </h2>
                  <p>{excerpt}</p>
                  <div className="text-right mt-3">
                    <Link
                      className="small stretched-link btn btn-sm btn-outline-white"
                      to={url}
                    >
                      Enter Now <i className="fa fa-chevron-double-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Fade>
      </div>
    );
  }
}
