import React from "react";

import HomeHero from "./Home/HomeHero";
import HeroIntro from "./General/HeroIntro";

import Cards from "./General/Cards";
import IFrameEmbed from "./General/IFrameEmbed";
import LogoLockup from "./General/LogoLockup";
import Stats from "./General/Stats";
import FAQBlock from "./General/FaqBlock";
import SimpleCTA from "./General/SimpleCTA";
import GeneralContent from "./General/GeneralContent";
import ImageText from "./General/ImageText";
import LargeQuote from "./General/LargeQuote";
import Testimonials from "./General/Testimonials";

import SidedList from "./General/SidedList";
import Images from "./General/Images";
import Video from "./General/Video";
import BlogLatest from "./Blog/BlogLatest";
import SignUp from "./General/SignUp";
import Competitions from "./Competitions/Competitions";

import { Fade } from "react-awesome-reveal";
import Partners from "./Partners/Partners";
import Columns from "./General/Columns";

const ModuleLayout = ({ page, blocks, bodyClass, url, preview }) => {
  return (
    <React.Fragment>
      {blocks &&
        blocks.map(function (block, i) {
          if (!block.type) {
            return false;
          } else if (block.type === "hero") {
            return (
              <HeroIntro
                key={"block" + i}
                headerType={block.headerType}
                subtitle={block.header0}
                title2={block.header1}
                title1={block.header2}
                massiveHero={block.massiveHero}
                excerpt={block.intro}
                content={block.content}
                classes={``}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                cta2Link={block.cta_2_link}
                cta2Text={block.cta_2_text}
                image1={block.image}
                background={block.background}
                // categories={categories}
              />
            );
          } else if (block.type === "home_hero") {
            return (
              <HomeHero
                key={"block" + i}
                intro_subheader={block.header0}
                intro_hello={block.header1}
                intro_title={block.header2}
                intro_intro={block.intro}
                intro_image={block.image}
                intro_image_mobile={block.image_mobile}
                testimonial_image={block.testimonialImage}
                testimonial_from={block.testimonialFrom}
                testimonial={block.testimonialContent}
                ctaLink={block.cta_1_link}
                ctaText={block.cta_1_text}
                cta2Link={block.cta_2_link}
                cta2Text={block.cta_2_text}
                logo_title={block.logo_title}
                logos={block.logos}
              />
            );
          } else if (block.type === "columns") {
            return (
              <Fade key={"block" + i}>
                <Columns {...block} />
              </Fade>
            );
          } else if (block.type === "cards") {
            return (
              <Fade key={"block" + i}>
                <Cards
                  title={block.title}
                  cards={block.cards}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "stats") {
            return (
              <Fade key={"block" + i}>
                <Stats
                  stats={block.stats}
                  statsTitle={block.statsTitle}
                  statsIntro={block.statsIntro}
                />
              </Fade>
            );
          } else if (block.type === "faqs") {
            return (
              <Fade key={"block" + i}>
                <FAQBlock
                  title={block.title}
                  intro={block.intro}
                  faqs={block.faqs}
                  ctaUrl={block.cta_1_link}
                  ctaText={block.cta_1_text}
                />
              </Fade>
            );
          } else if (block.type === "logolockup") {
            return (
              <Fade key={"block" + i}>
                <LogoLockup
                  title={block.title}
                  logos={block.logos}
                  fullHeight={block.fullHeight}
                />
              </Fade>
            );
          } else if (block.type === "simple_cta") {
            return (
              <SimpleCTA
                title={block.title}
                cta_1_link={block.cta_1_link}
                cta_1_text={block.cta_1_text}
                backgroundColour={block.background}
                key={"block" + i}
              />
            );
          } else if (block.type === "testimonialcards") {
            return (
              <Fade key={"block" + i}>
                <Testimonials title={block.title} cards={block.cards} />
              </Fade>
            );
          } else if (block.type === "large_quote") {
            return (
              <Fade key={"block" + i}>
                <LargeQuote
                  quote={block.testimonialContent}
                  quotee={block.testimonialFrom}
                  quotee_title={block.quotee_title}
                  largequote_image={block.testimonialImage}
                />
              </Fade>
            );
          } else if (block.type === "image_text") {
            return (
              <Fade key={"block" + i}>
                <ImageText
                  blockId={i}
                  title={block.title}
                  subtitle={block.subheading}
                  content={block.content}
                  imagetext_image={block.image}
                  image_order={block.image_leftright}
                  align={block.align}
                  cta_1_text={block.cta_1_text}
                  cta_1_link={block.cta_1_link}
                  cta_1_button={block.cta_1_button}
                  accent={block.color}
                  background={block.background}
                />
              </Fade>
            );
          } else if (block.type === "iframe") {
            return (
              <Fade key={"block" + i}>
                <IFrameEmbed
                  url={block.url}
                  include_side_content={block.include_side_content}
                  side_title={block.side_title}
                  side_content={block.side_content}
                />
              </Fade>
            );
          } else if (block.type === "general_content") {
            return (
              <Fade key={"block" + i}>
                <GeneralContent
                  full_width={block.full_width}
                  content={block.body}
                  backgroundColour={block.background}
                  alignment={block.align}
                />
              </Fade>
            );
          } else if (block.type === "sidedList") {
            return (
              <Fade key={"block" + i}>
                <SidedList
                  cards={block.cards}
                  title={block.title}
                  intro={block.intro}
                  ctaLink={block.cta_1_link}
                  ctaText={block.cta_1_text}
                  backgroundColour={block.background}
                  numbers={block.numbers}
                  columns={block.columns}
                />
              </Fade>
            );
          } else if (block.type === "images") {
            return (
              <Fade key={"block" + i}>
                <Images images={block.images} />
              </Fade>
            );
          } else if (block.type === "blog_latest") {
            return (
              <Fade key={"block" + i}>
                <BlogLatest {...block} preview={preview} />
              </Fade>
            );
          } else if (block.type === "signup") {
            return (
              <Fade key={"block" + i}>
                <SignUp {...block} />
              </Fade>
            );
          } else if (block.type === "competitions") {
            return (
              <Fade key={"block" + i}>
                <Competitions {...block} preview={preview} />
              </Fade>
            );
          } else if (block.type === "partners") {
            return (
              <Fade key={"block" + i}>
                <Partners {...block} preview={preview} />
              </Fade>
            );
          } else if (block.type === "video-block")
            return (
              <Fade key={"block" + i}>
                <Video
                  options={block.options}
                  thumbnail={block.thumbnail}
                  full_width={block.full_width}
                  video={block.video}
                  caption={block.caption}
                  controls={block.controls}
                  muted={block.autoplay}
                  playing={block.autoplay}
                />
              </Fade>
            );
          else return <div>{block.type}</div>;
        })}
    </React.Fragment>
  );
};

export default ModuleLayout;
