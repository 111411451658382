import React from "react";

import Image from "../Hacks/Image";
import Content from "../Hacks/Content";

import { Link } from "gatsby";

export const ImageText = ({
  image_order,
  align,
  imagetext_image,
  title,
  subtitle,
  content,
  blockId,
  cta_1_text,
  cta_1_link,
  cta_1_button,
  accent = "secondary",
  background,
}) => {
  return (
    <section
      className={`imagetext-block container-fluid py-4 py-lg-5 ${
        background ? "bg-" + background : ""
      }`}
    >
      <div className={`row contained-xl pt-lg-5`}>
        <div
          className={`imagetext-block-image col-12 col-md-5 col-lg-5 mb-3 mb-lg-0
            ${align === "middle" ? "align-self-center" : ""}
            ${image_order === "RIGHT" ? "order-lg-2 " : "offset-lg-1"}
          `}
        >
          {imagetext_image && (
            <>
              {cta_1_link ? (
                <Link to={cta_1_link}>
                  <Image
                    image={imagetext_image}
                    className={`w-100 max-width-100`}
                    alt=""
                  />
                </Link>
              ) : (
                <Image
                  image={imagetext_image}
                  className={`w-100 max-width-100`}
                  alt=""
                />
              )}
            </>
          )}
        </div>
        <div
          className={`imagetext-block-text col-md-7 col-lg-5  mb-3
            ${
              align === "middle" ? "align-middle d-flex align-items-center" : ""
            }
            ${image_order === "RIGHT" ? "offset-lg-1 pr-lg-5" : "pl-lg-5"}
          `}
        >
          <div>
            {subtitle && (
              <h3 className={`h5 mb-1 font-weight-bold text-${accent}`}>
                {subtitle}
              </h3>
            )}
            {title && <h2 className="h3 font-family-display mb-4">{title}</h2>}
            <Content className={`wysiwyg`} content={content} />
            {cta_1_text && cta_1_link && (
              <Link
                cover={`true`}
                direction="left"
                className={`mt-2 ${
                  cta_1_button ? `btn btn-${accent}` : `text-${accent}`
                }`}
                to={cta_1_link}
              >
                {cta_1_text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageText;
