import React from "react";
import { Link } from "gatsby";
import showdown from "showdown";
import Content from "../Hacks/Content";
const converter = new showdown.Converter();

export const Cards = ({ title, cards, background }) => {
  return (
    <section
      id="Cards"
      className={`Cards container-fluid pt-5 bg-${background}`}
    >
      <h2 className="h4 mt-lg-5 mb-4 text-center">{title}</h2>
      <div className="row contained-xl mx-auto justify-content-center">
        <div className="pb-5 col-lg-11">
          <div className="row justify-content-center">
            {cards &&
              cards.map(function (card, i) {
                return (
                  <div className="col-md-6 col-lg-3 mb-4" key={`card_` + i}>
                    <div className="card rounded h-100 p-4 ">
                      <div className="card-body">
                        {card.title && (
                          <h3 className="h2 mb-0 text-center text-lg-left">
                            {card.title}
                          </h3>
                        )}
                        {card.subtitle && (
                          <h4
                            className={`h6 text-center text-lg-left text-${card.color} mb-4`}
                          >
                            {card.subtitle}
                          </h4>
                        )}
                        {card.intro && <p>{card.intro}</p>}

                        <Content
                          className={`fa-list-checks fa-list-${card.color}`}
                          content={converter.makeHtml(card.content)}
                        />

                        {card.link && (
                          <p className="text-center text-lg-left mb-0">
                            <Link
                              cover={`true`}
                              direction="left"
                              className={`btn btn-${card.color} mt-1 mt-3`}
                              to={card.link}
                            >
                              Learn More{" "}
                              <span className="sr-only">
                                our {card.title} solution
                              </span>
                            </Link>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards;
