import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

export default class CompetitionCard extends React.Component {
  render() {
    const { featured_media, title, excerpt, url } = this.props;

    return (
      <div className="rounded overflow-hidden position-relative card bg-white h-100">
        <Fade>
          <>
            <div className="card-header p-0 position-relative">
              {featured_media && (
                <Image
                  image={featured_media}
                  className="w-100 bg-white floaty card-journal__image"
                  alt={title}
                />
              )}
              {!featured_media && (
                <div className="card-journal__image bg-primary" />
              )}
            </div>

            <div className="card-body pb-5">
              <h2 className="h4 font-family-display card-title">
                <span>{title}</span>
              </h2>
              <p className="card-text mb-4">{excerpt}</p>
              <Link
                className="small stretched-link btn btn-sm btn-outline-primary"
                to={url}
              >
                View <i className="fa fa-chevron-double-right" />
              </Link>
            </div>
          </>
        </Fade>
      </div>
    );
  }
}
